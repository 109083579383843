import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import faunadb, { query } from 'faunadb';

const ContactPage = () => {
  const [sent, setSent] = useState(false)
  const [message, setMessage] = useState('')
  const send = async (message) => {
    if (message.trim() === '') return
    try {
      const client = new faunadb.Client({
        secret: 'fnAD4Yg9dFACCOUo0N4QFNTHlK8G1QjQE6EtuHSV',
      });
      const q = query;
      await client.query(
        q.Create(
          q.Collection('Message'),
          { data: { text: message, created: Date.now() } }
        )
      );
      setSent(true)
    } catch (err) {
      console.log(err)
      alert('didn\'t send, check console for error.')
    }
  }
  return (
    <Layout>
      <SEO title="Jason Sebring :: the Jason Sebring that owns this domain" />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
      }}>
        {sent ?
        <div style={{
            fontSize: 20
        }}>
            Got it. Thanks.
        </div>
        :
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
        }}>
            <h2>Message</h2>
            <textarea style={{
                height: 100,
                width: '80%',
                maxWidth: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
                marginBottom: 20,
                padding: 10
            }} placeholder="leave a message..."
                onChange={ev => setMessage(ev.target.value)} value={message} />
            <button onClick={() => send(message)}>Send</button>
        </div>
        }
      </div>
    </Layout>
  )
}

export default ContactPage
